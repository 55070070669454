import React, { useEffect, useState } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";

import {
  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
  getInspecciones,
  actualizarInspecciones,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

const ProjectStatus = ({ status }) => {
  switch (status) {
    case "Pending":
      return <Badge className="bg-warning"> {status} </Badge>;

    case "Delay":
      return <Badge className="bg-danger"> {status} </Badge>;

    case "Completed":
      return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-success"> {status} </Badge>;
  }
};

const TiposInspecciones = () => {

  //meta title
  document.title = "Tipos de inspecciones para el proyecto";

  const dispatch = useDispatch();
  const [project, setProject] = useState();
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (project && project.id) || "",
      img: (project && project.img) || "",
      name: (project && project.name) || "",
      description: (project && project.description) || "",
      status: (project && project.status) || "",
      color: (project && project.color) || "",
      dueDate: (project && project.dueDate) || "",
      team: (project && project.team) || "",
    },
    validationSchema: Yup.object({
      nombreDeInspeccion: Yup.string().required(
        "Por favor ingrese un nombre para la inspección"
      ),
      campo1: Yup.string().required(
        "Por favor ingrese el parámetro que se evaluará"
      ),
      contenidoCampo1: Yup.string().required(
        "Por favor indique cómo se medirá el parámetro."
      ),
      /*
      color: Yup.string().required(
        "Please Enter Your Color"
      ),*/
    }),
    onSubmit: (values) => {
      console.log("entrando a onsubmit")
      if (isEdit) {
        console.log("valor de isEdit "+isEdit)
        const actualizarInspeccion = {
          idProyecto: proyecto.id,
          nombreInspeccion: values.name,
          campo1: values.campo1,
          contenidoCampo1: values.contenidoCampo1,

        };
        toggle();

        // update project
        dispatch(actualizarInspecciones(actualizarInspeccion));
      } else {
        if(isEmpty(fileName)){
          console.log("filename:"+fileName);
          alert("No se ha seleccionado un archivo");
        }
        else{
        console.log("empezando a grabar archivo")
        const newProject = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          description: values["description"],
          status: values["status"],
          color: values["color"],
          dueDate: values["dueDate"],
          team: values["team"],
        };
        // save new project
        
        //dispatch(onAddNewProject(newProject));

        console.log("empezando a grabar archivo")
        console.log("nombre de archivo en state:"+fileName)
        console.log("contenido de archivo en state:"+file)
        console.log("value.nombreDeInspeccion:"+values.nombreDeInspeccion)
        console.log("value.campo1:"+values.campo1)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("nombreInspeccion", values.nombreDeInspeccion);
        formData.append("campo1", values.campo1);
        formData.append("contenidoCampo1", values.contenidoCampo1);
        formData.append("idProyecto", proyecto.id);
        //formData.append("campo2", values.campo2);
        //formData.append("contenidoCampo1", values.contenidoCampo1);

        

        console.log("contenido de formData:"+formData)

        axios.post('https://servidorpruebapp.itnmexico.com/tipos-inspecciones/',
        formData  
        )
        .then((response) => {
        
        console.log("esta es la respuesta: "+response);
        alert("Se ha guardado la inspección exitosamente")
        
 
      })
      .catch(err => console.log("hay un error:"+err))
    }
        setFileName("");
        console.log("filename:"+fileName);
      }
      toggleIsAdd();
    },
  });

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));
  const { proyecto } = useSelector(state => ({
    proyecto: state.projects.project,
  }));
  const { inspecciones } = useSelector(state => ({
    inspecciones: state.projects.inspecciones,
  }));


  console.log("proyecto.id:"+proyecto.id);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const [isAdd, setIsAdd] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  console.log("filename:"+fileName);



  const saveFile = (e) => {
    console.log("saving file")
    if(e.target.files[0]){
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      console.log("nombre de archivo en state: "+fileName)
      console.log("archivo en state: "+file)

      console.log("e.target"+e.target.files[0].name)
    }
    else{
      alert("No se ha seleccionado un archivo")
    }
  };

  const[listaDeProyectos, setListaDeProyectos] = useState([]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  };

  const toggleIsAdd = () => {
    if (modalAdd) {
      setModalAdd(false);
      setProject(null);
    } else {
      setModalAdd(true);
    }
  };


  const handleProjectClick = arg => {
    const project = arg;
/*
    setProject({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    });
*/
    setIsEdit(true);

    console.log("cambiando isEdit a true")

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (project) => {
    setProject(project);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (project && project.id) {
      dispatch(onDeleteProject(project.id));
    }
    setDeleteModal(false);
  };

  var idProyecto = proyecto.id;

  //var modal_standard = false;

  const[modal_standard, setmodal_standard] = useState(false);

  const[bool_inspecciones, setbool_inspecciones] = useState(false);

  const[sin_inspecciones, setsin_inspecciones] = useState([]);

  var  provisional = [];


  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/inspecciones/'+proyecto.id)
    .then((response) => {
      if(isEmpty(response.data)){
        console.log("array de inspecciones vacío")
        tog_standard();
        tog_bool();
        
        for(var i=1; i<=proyecto.noInspecciones; i++){
          var inspect = {key: i, inspeccion:"Inspección #"+i}
          provisional.push(inspect)
          
        }
        
        setsin_inspecciones(provisional);
        console.log("sin_inspecciones:"+sin_inspecciones)
        
      }
      else{
        dispatch(getInspecciones(response.data));
        setListaDeProyectos(response.data);
        console.log("response.data")
        console.log(response.data)
      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })
    .catch(err => console.log(err))
    }, [])

    function tog_standard() {
      setmodal_standard(!modal_standard);
      console.log(modal_standard);
    }

    function tog_bool() {
      setbool_inspecciones(!bool_inspecciones);
      console.log("bool_inspecciones:" + bool_inspecciones);
    }


  useEffect(() => {
    dispatch(onGetProjects());
  }, [dispatch]);

  useEffect(() => {
    setProjectList(projects);
  }, [projects]);

  useEffect(() => {
    if (!isEmpty(projects)) {
      setProjectList(projects);
    }
  }, [projects]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={proyecto.nombre} breadcrumbItem="Tipos de inspecciones" />
          {console.log("modal standard:" + modal_standard)}
          <Row>
            <Col lg="12">
              <div >
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Parámetro inspeccionado</th>
                        <th scope="col">Tipo de inspección</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                    {console.log("sin_inspecciones: "+ sin_inspecciones)}
                    {console.log("listaDeProyectos: "+ listaDeProyectos)
                    }
                    {console.log("bool_inspecciones: "+ bool_inspecciones)
                    }
                    { bool_inspecciones 
                      ? console.log("no hay inspecciones por mostrar")
                      : map(listaDeProyectos, (project, index) => (
                        <tr key={index}>
                          <td>
                            {index+1}
                            {/** <img
                              src={companies[project.img]}
                              alt=""
                              className="avatar-sm"
                            />
                            */}
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/projects-overview/${project.id}`}
                                className="text-dark"
                              >
                                {project.nombreInspeccion}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">
                              
                            </p>
                          </td>
                          <td> {project.campo1}</td>
                          <td>
                            {project.contenidoCampo1}
                          </td>
                          <td>
                            {project.archivoNombre}
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="a"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  href="#"
                                  onClick={() => handleProjectClick(project)}
                                >
                                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                  Editar
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={() => onClickDelete(project)}
                                >
                                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                  Eliminar
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    {console.log("proyecto.noInspecciones:"+proyecto.noInspecciones)}

                      
                    </tbody>
                  </Table>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Editar Inspección" : "Add Project"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(e);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Input
                              type="hidden"
                              value={validation.values.img || ""}
                              name="img"
                            />

                            <Input
                              type="hidden"
                              value={validation.values.team || ""}
                              name="team"
                            />
                            <div className="mb-3">
                              <Label className="form-label">Nombre</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                name="status"
                                id="status1"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.status || ""
                                }
                              >
                                <option>Completed</option>
                                <option>Pending</option>
                                <option>Delay</option>
                              </Input>
                              {validation.touched.status && validation.errors.status ? (
                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Color</Label>
                              <Input
                                name="color"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.color || ""}
                                invalid={
                                  validation.touched.color && validation.errors.color ? true : false
                                }
                              >
                                <option>success</option>
                                <option>warning</option>
                                <option>danger</option>
                              </Input>
                              {validation.touched.color && validation.errors.color ? (
                                <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">dueDate</Label>
                              <Input
                                name="dueDate"
                                type="date"
                                format="YYYY/MM/DD"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.dueDate && validation.errors.dueDate ? true : false
                                }
                                value={validation.values.dueDate || ""}
                              ></Input>
                              {validation.touched.dueDate && validation.errors.dueDate ? (
                                <FormFeedback type="invalid">{validation.errors.dueDate}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Guardar
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <button
                  type="button"
                  onClick={() => {
                    toggleIsAdd();
                  }}
                  className="btn btn-success save-user"
                 >
                  Añadir Inspección
                  </button>
               {/*  <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
                */}
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
              }}
            >
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0" id="myModalLabel">
                                        Alerta
                                      </h5>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          tog_standard();
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <h5>No se han definido inspecciones para este proyecto, favor de añadirlas.</h5>
                                      
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          tog_standard();
                                          //navigate("/inicio");
                                        }}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                      >
                                        Cerrar
                                      </button>
                                      
                                    </div>
                                  </Modal>
                  
                  <Modal isOpen={modalAdd} toggle={toggleIsAdd}>
                    <ModalHeader toggle={toggleIsAdd} tag="h4">
                      Agregar Inspecciones
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Nombre de la Inspección</Label>
                              <Input
                                name="nombreDeInspeccion"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nombreDeInspeccion || ""}
                                invalid={
                                  validation.touched.nombreDeInspeccion && validation.errors.nombreDeInspeccion ? true : false
                                }
                              />
                              {validation.touched.nombreDeInspeccion && validation.errors.nombreDeInspeccion ? (
                                <FormFeedback type="invalid">{validation.errors.nombreDeInspeccion}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">¿Qué deseas inspeccionar?</Label>
                              <Input
                                name="campo1"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.campo1 || ""}
                                invalid={
                                  validation.touched.campo1 && validation.errors.campo1 ? true : false
                                }
                              />
                              {validation.touched.campo1 && validation.errors.campo1 ? (
                                <FormFeedback type="invalid">{validation.errors.campo1}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">¿Cómo se medirá?</Label>
                              <Input
                                name="contenidoCampo1"
                                id="contenidoCampo1"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.contenidoCampo1 || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                <option>Texto</option>
                                <option>Valor Numérico</option>
                                <option>Verdadero o Falso</option>
                              </Input>
                              {validation.touched.contenidoCampo1 && validation.errors.contenidoCampo1 ? (
                                <FormFeedback type="invalid">{validation.errors.contenidoCampo1}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mt-4 mt-md-0">
                              <Label className="form-label">¿Deseas agregar un archivo?</Label>
                                      <Input
                                        name="archivo"
                                        id="archivo"
                                        type="file"
                                        className="form-control"
                                        defaultValue=""
                                        onChange={saveFile}
                                      />
                              </div>
                            
                            

                            
                            
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Guardar
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(TiposInspecciones);

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import moment from"moment";

import axios from "axios";

// Import Editor
//import { Editor } from "react-draft-wysiwyg";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useSelector, useDispatch } from "react-redux";

const TasksCreate = () => {

  //meta title
  document.title="Crear Tareas para el proyecto";

  const inpRow = [{ name: "", file: "" }]
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [inputFields, setinputFields] = useState(inpRow)

  const startDateChange = date => {
    var newdate = moment(date).format('YYYY-MM-DD')
    setstartDate(date)
    console.log("fecha:"+newdate)
  }

  const endDateChange = date => {
    setendDate(date)
  }

  // Function for Create Input Fields
  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    document.getElementById("nested" + idx).style.display = "none"
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      taskname:  "",
      taskDescription: "",
      miembroEquipo: "",
    },
    validationSchema: Yup.object({
      taskname: Yup.string().required(
        "Por favor ingrese un nombre para la tarea"
      ),
      taskDescription: Yup.string().required(
        "Por favor ingrese una descripción para la tarea"
      ),
      miembroEquipo: Yup.string().required(
        "Por favor ingrese un usuario"
      ),
      noNivel: Yup.string().required(
        "Por favor ingrese un nivel"
      ),
      noTakt: Yup.string().required(
        "Por favor ingrese un Takt"
      ),
      //file: Yup.mixed().required(),
      /*contenidoCampo1: Yup.string().required(
        "Por favor indique cómo se medirá el parámetro."
      ),
      color: Yup.string().required(
        "Please Enter Your Color"
      ),*/
    }),
    onSubmit: (values) => {
      if(file){
      console.log("entrando a onsubmit")
       console.log("empezando a grabar archivo")
        
        console.log("nombre de archivo en state:"+fileName)
        console.log("contenido de archivo en state:"+file)
        console.log("value.nombreDeInspeccion:"+values.nombreDeInspeccion)
        console.log("value.campo1:"+values.campo1)
        //var fecha = startDate.toISOstring().split('T');
        var newdate = moment(startDate).format('YYYY-MM-DD')
        //console.log("fecha curada:"+fecha)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("nombreTarea", values.taskname);
        formData.append("descripcionTarea", values.taskDescription);
        formData.append("fechaCreacion", startDate);
        formData.append("idProyecto", proyecto.id);
        formData.append("idUsuarioCreador", 1);
        formData.append("idUsuarioConTarea", values.miembroEquipo);
        formData.append("noNivel", values.noNivel);
        formData.append("noTakt", values.noTakt);
        formData.append("estatusTarea", 0);

        //formData.append("contenidoCampo1", values.contenidoCampo1);

        

        console.log("contenido de formData:"+formData)

        axios.post("https://servidorpruebapp.itnmexico.com/tareas/",
        formData  
        )
      

      .then((response) => {
        
        console.log("esta es la respuesta: "+response);
        alert("La tarea ha sido guardado.")
        
        
 
      })
      .catch(err => console.log(err))
      
      } 
      else{
        alert("No se ha seleccionado un archivo")
      }
    },
  });

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));
  const { proyecto } = useSelector(state => ({
    proyecto: state.projects.project,
  }));
  const { inspecciones } = useSelector(state => ({
    inspecciones: state.projects.inspecciones,
  }));

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const temp = [...Array(proyecto.noNiveles)];
  const temp2 = [...Array(proyecto.noTakts)];


  const saveFile = (e) => {
    console.log("saving file")
    if(e.target.files[0]){
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      console.log("nombre de archivo en state: "+fileName)
      console.log("e.target"+e.target.files[0].name)
    }
    else{
      alert("No se ha seleccionado un archivo")
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tareas" breadcrumbItem="Crear Tarea" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Crear Nueva Tarea</CardTitle>
                  <Form
                        className="outer-repeater"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                  {/** <form className="outer-repeater">*/}
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Nombre de la Tarea
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              placeholder="Ingrese un nombre para la tarea..."
                              onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taskname || ""}
                                invalid={
                                  validation.touched.taskname && validation.errors.taskname ? true : false
                                }
                            />
                            {validation.touched.taskname && validation.errors.taskname ? (
                                <FormFeedback type="invalid">{validation.errors.taskname}</FormFeedback>
                              ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Descripción de la tarea
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskDescription"
                              name="taskDescription"
                              type="text"
                              className="form-control"
                              placeholder="Ingrese un nombre para la tarea..."
                              onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taskDescription || ""}
                                invalid={
                                  validation.touched.taskDescription && validation.errors.taskDescription ? true : false
                                }
                            />
                            {validation.touched.taskDescription && validation.errors.taskDescription ? (
                                <FormFeedback type="invalid">{validation.errors.taskDescription}</FormFeedback>
                              ) : null}
                          </Col>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Selecciona el nivel
                          </Label>
                          <Col lg="10">
                            <Input
                                name="noNivel"
                                id="noNivel"
                                type="select"
                                className="form-select"
                                
                                onChange={
                                  validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.noNivel || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                {console.log("temp:"+temp.length)}
                                {
                                  
                                  temp.map((nivel, key) => (
                                    <option key={key}>
                                      {key+1}
                                  </option> 
                                
                                  )
                                  )
                                };
                                
                                
                              </Input>
                              {validation.touched.noNivel && validation.errors.noNivel ? (
                                <FormFeedback type="invalid">{validation.errors.noNivel}</FormFeedback>
                              ) : null}
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Selecciona el Takt
                          </Label>
                          <Col lg="10">
                          <Input
                                name="noTakt"
                                id="noTakt"
                                type="select"
                                className="form-select"
                                
                                onChange={
                                  validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.noTakt || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                {console.log("temp2:"+temp2.length)}
                                {
                                  
                                  temp2.map((nivel, key) => (
                                    <option key={key}>
                                      {key+1}
                                  </option> 
                                
                                  )
                                  )
                                };
                                
                                
                              </Input>
                              {validation.touched.noTakt && validation.errors.noTakt ? (
                                <FormFeedback type="invalid">{validation.errors.noTakt}</FormFeedback>
                              ) : null}
                            </Col>
                          </FormGroup>


                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Fecha de Creación
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col md={6} className="pr-0">
                                <DatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={startDateChange}
                                />
                              </Col>
                              {/*<Col md={6} className="pl-0">
                                <DatePicker
                                  className="form-control"
                                  selected={endDate}
                                  onChange={endDateChange}
                                />
                              </Col>
                              */}
                            </Row>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                              Agrega un miembro del Equipo
                            </Label>
                          <div className="col-lg-10">
                            <Input
                              id="miembroEquipo"
                              name="miembroEquipo"
                              type="text"
                              placeholder="Ingrese el nombre..."
                              className="form-control"
                              onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.miembroEquipo || ""}
                                invalid={
                                  validation.touched.miembroEquipo && validation.errors.miembroEquipo ? true : false
                                }
                            />
                            {validation.touched.miembroEquipo && validation.errors.miembroEquipo ? (
                                <FormFeedback type="invalid">{validation.errors.miembroEquipo}</FormFeedback>
                              ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                              Agrega un archivo de respaldo
                            </Label>
                          <div className="col-lg-10">
                          <Input
                                        id="file" 
                                        name="file"
                                        type="file"
                                        className="form-control"
                                        defaultValue=""
                                        onChange={saveFile}
                                      />
                          </div>
                        </FormGroup>


{/** 


                        <div className="inner-repeater mb-4">
                          <div className="inner form-group mb-0 row">
                            <Label className="col-form-label col-lg-2">
                              Agrega un miembro del Equipo
                            </Label>
                            <div
                              className="inner col-lg-10 ml-md-auto"
                              id="repeater"
                            >
                              {inputFields.map((field, key) => (
                                <div
                                  key={key}
                                  id={"nested" + key}
                                  className="mb-3 row align-items-center"
                                >
                                  <Col md="6">
                                    <input
                                      type="text"
                                      className="inner form-control"
                                      defaultValue={field.name}
                                      placeholder="Ingrese el nombre"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <div className="mt-4 mt-md-0">
                                      <Input
                                        type="file"
                                        className="form-control"
                                        defaultValue={field.file}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="2">
                                    <div className="mt-2 mt-md-0 d-grid">
                                      <Button
                                        color="primary"
                                        className="inner"
                                        onClick={() => {
                                          handleRemoveFields(key)
                                        }}
                                        block
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </Col>
                                </div>
                              ))}
                            </div>
                          </div>
                          <Row className="justify-content-end">
                            <Col lg="10">
                              <Button
                                color="success"
                                className="inner"
                                onClick={() => {
                                  handleAddFields()
                                }}
                              >
                                Add Number
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Budget
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>


*/}



                      </div>
                    </div>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                        Crear Tarea
                        </Button>
                      </Col>
                    </Row>
                  {/** </form>*/}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TasksCreate

import React, { useState } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import axios from "axios";

const FormWizard = () => {

  //meta title
  document.title="Crear Proyecto Nuevo";

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [TipoProyecto, setTipoProyecto] = useState("NA")

  const [NombreProyecto, setNombreProyecto] = useState("")

  const [ProjectManager, setProjectManager] = useState("")

  const [NoTelefono, setNoTelefono] = useState("")

  const [Email, setEmail] = useState("")

  const [Direccion, setDireccion] = useState("")

  const [Takts, setTakts] = useState("")

  const [Niveles, setNiveles] = useState("")

  
  const [Torres, setTorres] = useState("")

  const [Casas, setCasas] = useState("")

  const [Inspecciones, setInspecciones] = useState("")

  const [modal_standard, setmodal_standard] = useState(false);

  
  const guardarProyecto = () => {
    console.log("guardando proyecto")
    axios.post("https://servidorpruebapp.itnmexico.com/proyectos/",
      { nombre: NombreProyecto,
        ubicacion: Direccion,
        manager: ProjectManager,
        tipo: TipoProyecto,
        telefono: NoTelefono,
        correo: Email,
        noTakts: Takts,
        noTorres: Torres,
        noCasas: Casas,
        noInspecciones: Inspecciones,
        noNiveles: Niveles,
      }  
    )
      .then((response) => {
        
        console.log(response);
        tog_standard();
 
      })
      .catch(err => console.log(err))
    
  };
  

  const handleChange = (e) => {
    setTipoProyecto(e.target.value);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    //removeBodyCss();
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  function Tipo({ name }) {
    if (name == "NA"){
      return (
      <div></div>
      );
    }
    else if (name == "NI"){
      return (
        <div>
          <Label for="basicpill-pancard-input5">
            ¿Cuántos Takts se han definido?
          </Label>
          <Input
            type="text"
            className="form-control"
            id="basicpill-pancard-input5"
            placeholder="Número de Takts"
            onChange={event => {
              setTakts(event.target.value)
            }
          }
          />
        </div>                          
                );
    }
    else if (name == "TH"){
      return (
        <div>
          <Label for="basicpill-vatno-input6">
            ¿Con cuántas torres cuenta el proyecto?
          </Label>
          <Input
            type="text"
            //className="form-control"
            //id="basicpill-vatno-input6"
            placeholder="Número de Torres"
            onChange={event => {
              setTorres(event.target.value)
            }
          }
          />
          <Label for="basicpill-vatno-input6">
            ¿Cuántos Takts se han definido en el proyecto en total?
          </Label>
          <Input
            type="text"
            className="form-control"
            id="basicpill-namecard-input11"
            //placeholder="Número de Takts"
            onChange={event => {
              setTakts(event.target.value)
            }
          }
          />
        </div>                          
                );
    }
    else if (name == "CH"){
      return (
        <div>
          <Label for="basicpill-vatno-input6">
            ¿Con cuántas casas cuenta el proyecto?
          </Label>
          <Input
            type="text"
            className="form-control"
            id="basicpill-namecard-input11"
            placeholder="Número de Casas"
            onChange={event => {
              setCasas(event.target.value)
            }
          }
          />
          <Label for="basicpill-vatno-input6">
            ¿Cuántos Takts se han definido en el proyecto en total?
          </Label>
          <Input
            type="text"
            className="form-control"
            id="basicpill-vatno-input6"
            placeholder="Número de Takts"
            onChange={event => {
              setTakts(event.target.value)
            }
          }
          />
        </div>                          
                );
    }
    else if (name == "OT"){
      return (
        <div>
          <Label for="basicpill-vatno-input6">
            ¿Cuántos Takts se han definido?
          </Label>
          <Input
            type="text"
            className="form-control"
            id="basicpill-vatno-input6"
            placeholder="Número de Takts"
            onChange={event => {
              setTakts(event.target.value)
            }
          }
          />
        </div>                          
                );
    }

    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Nuevo" breadcrumbItem="Crear Proyecto" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Principales</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Generales
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Tipo de Proyecto
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Inspecciones
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Confirmar Detalles
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Nombre del proyecto
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="nombreProyecto"
                                    placeholder="Nombre del proyecto"
                                    onChange={event => {
                                      setNombreProyecto(event.target.value)
                                    }
                                  }
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Project Manager
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder="Project Manager"
                                    onChange={event => {
                                      setProjectManager(event.target.value)
                                    }
                                  }
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-phoneno-input3">
                                    Teléfono
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input3"
                                    placeholder="No. Telefónico"
                                    onChange={event => {
                                      setNoTelefono(event.target.value)
                                    }
                                  }
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    Email
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Email"
                                    onChange={event => {
                                      setEmail(event.target.value)
                                    }
                                  }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">
                                    Dirección
                                  </Label>
                                  <textarea
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="2"
                                    placeholder="Dirección del proyecto"
                                    onChange={event => {
                                      setDireccion(event.target.value)
                                    }
                                  }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>



                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Selecciona el tipo de proyecto</Label>
                                    <select className="form-select" value={TipoProyecto} onChange={handleChange}>
                                      <option defaultValue>
                                        Tipo de proyecto
                                      </option>
                                      <option value="NI">
                                        Nave industrial
                                      </option>
                                      <option value="TH">Torres Habitacionales</option>
                                      <option value="CH">Casas Habitacionales</option>
                                      <option value="OT">Otro</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                      <Label for="basicpill-pancard-input5">
                                        ¿Cuántos Takts se han definido?
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-pancard-input5"
                                        placeholder="Número de Takts"
                                        onChange={event => {
                                          setTakts(event.target.value)
                                        }
                                      }
                                      />
                                      </div>
                                      </Col>
                                      <Col lg="6">
                                  <div className="mb-3">
                                      <Label for="basicpill-pancard-input5">
                                        ¿Cuántos niveles tiene el proyecto?
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-pancard-input5"
                                        placeholder="Número de Niveles"
                                        onChange={event => {
                                          setNiveles(event.target.value)
                                        }
                                      }
                                      />
                                      </div>
                                      </Col>

                               {/**  <Col lg="6">
                                  <div className="mb-3">
                                    <Tipo name = {TipoProyecto} />
                                    
                                  </div>
                                </Col>
                                */}
                              </Row>
                              {/*
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-cstno-input7">
                                      CST No.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cstno-input7"
                                      placeholder="Enter Your CST No."
                                    />
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-servicetax-input8">
                                      Service Tax No.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-servicetax-input8"
                                      placeholder="Enter Your Service Tax No."
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-companyuin-input9">
                                      Company UIN
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-companyuin-input9"
                                      placeholder="Enter Your Company UIN"
                                    />
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      Declaration
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      placeholder="Declaration Details"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              */}
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      ¿Cuántas inspecciones se realizarán?
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Número de inspecciones"
                                      onChange={event => {
                                        setInspecciones(event.target.value)
                                      }
                                    }
                                    />
                                  </div>
                                </Col>                                
                              </Row>
                              {/* 
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                      Credit Card Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cardno-input12"
                                      placeholder="Credit Card Number"
                                    />
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-card-verification-input0">
                                      Card Verification Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-card-verification-input0"
                                      placeholder="Credit Verification Number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-expiration-input13">
                                      Expiration Date
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-expiration-input13"
                                      placeholder="Card Expiration Date"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              */}
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                {/* 
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                */}
                                <div>
                                  <h5>Confirmar Información</h5>
                                  <p className="text-muted">
                                    Si la información es correcta favor de dar click en el botón Guardar
                                  </p>
                                  <Button
                                    color="success"
                                    outline
                                    onClick={guardarProyecto}
                                  >
                                    Guardar
                                  </Button>
                                  <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                      tog_standard();
                                    }}
                                  >
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0" id="myModalLabel">
                                        Éxito
                                      </h5>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setmodal_standard(false);
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <h5>Se ha guardado con éxito la información.</h5>
                                      
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          tog_standard();
                                        }}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                      >
                                        Cerrar
                                      </button>
                                      
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Anterior
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Siguiente
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

{/** 

            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Vertical Wizard</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1.</span> Seller Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Company Document</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 3,
                              }),
                                "done")
                            }
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            <span className="number">3.</span> Bank Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 4,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 4,
                              }),
                                "done")
                            }
                            onClick={() => {
                              toggleTabVertical(4)
                            }}
                            disabled={!(passedStepsVertical || []).includes(4)}
                          >
                            <span className="number">4.</span> Confirm Detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    First name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input12"
                                    placeholder="Enter Your First Name"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-lastname-input22">
                                    Last name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input22"
                                    placeholder="Enter Your Last Name"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-phoneno-input32">
                                    Phone
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input32"
                                    placeholder="Enter Your Phone No."
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-email-input42">
                                    Email
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-email-input42"
                                    placeholder="Enter Your Email ID"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-address-input12">
                                    Address
                                  </Label>
                                  <textarea
                                    id="basicpill-address-input12"
                                    className="form-control"
                                    rows="2"
                                    placeholder="Enter Your Address"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-pancard-input52">
                                      PAN Card
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-pancard-input52"
                                      placeholder="Enter Your PAN Card No."
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-vatno-input62">
                                      VAT/TIN No.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-vatno-input62"
                                      placeholder="Enter Your VAT/TIN No."
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-cstno-input72">
                                      CST No.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cstno-input72"
                                      placeholder="Enter Your CST No."
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-servicetax-input82">
                                      Service Tax No.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-servicetax-input82"
                                      placeholder="Enter Your Service Tax No."
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-companyuin-input92">
                                      Company UIN
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-companyuin-input92"
                                      placeholder="Company UIN No."
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-declaration-input102">
                                      Declaration
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-Declaration-input102"
                                      placeholder="Declaration Details"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-namecard-input112">
                                      Name on Card
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input112"
                                      placeholder="Enter Your Name on Card"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>Credit Card Type</Label>
                                    <select className="form-select">
                                      <option>Select Card Type</option>
                                      <option>American Express</option>
                                      <option>Visa</option>
                                      <option>MasterCard</option>
                                      <option>Discover</option>
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-cardno-input122">
                                      Credit Card Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cardno-input122"
                                      placeholder="Enter Your Card Number"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-card-verification-input">
                                      Card Verification Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-card-verification-input"
                                      placeholder="Card Verification Number"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-expiration-input132">
                                      Expiration Date
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-expiration-input132"
                                      placeholder="Card Expiration Date"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    If several languages coalesce, the grammar
                                    of the resulting
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            activeTabVartical === 4 ? "next disabled" : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical + 1)
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, CardTitle } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts";

//Import Images
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import { getTasks as onGetTasks } from "../../store/tasks/actions";
import { options, series, } from "common/data/tasks";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { isEmpty, map } from "lodash";


const TasksList = () => {
  //meta title
  document.title = "Lista de Tareas para el proyecto";

  const dispatch = useDispatch();

  const { tasks } = useSelector(state => ({
    tasks: state.tasks.tasks,
  }));

  const { proyecto } = useSelector(state => ({
    proyecto: state.projects.project,
  }));

  useEffect(() => {
    dispatch(onGetTasks());
  }, [dispatch]);

  const[listaDeTareas, setlistaDeTareas] = useState([]);

  const[listaDeTareasPendientes, setlistaDeTareasPendientes] = useState([]);

  const[listaDeTareasProceso, setlistaDeTareasProceso] = useState([]);

  const[listaDeTareasTerminadas, setlistaDeTareasTerminadas] = useState([]);

  const[listaDeTareasDescendientes, setlistaDeTareasDescendientes] = useState([]);

  const[bool_inspecciones, setbool_inspecciones] = useState(false);

  const[numDeSeries, setnumDeSeries] = useState([]);

  function tog_bool() {
    setbool_inspecciones(!bool_inspecciones);
    console.log("bool_inspecciones:" + bool_inspecciones);
  }



  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/tareas/'+proyecto.id)
    .then((response) => {
      if(isEmpty(response.data)){
        console.log("array de inspecciones vacío");
        alert("No existen tareas para este proyecto.");
        //tog_standard();
        tog_bool();
        
        
      }
      else{
        //dispatch(getInspecciones(response.data));
        setlistaDeTareas(response.data);
        console.log("response.data: "+response.data)
        const temp = response.data.filter((elemento) => elemento.estatusTarea == 0);
        console.log("temp: "+temp.length)
        setlistaDeTareasPendientes(temp);

        const temp1 = response.data.filter((elemento) => elemento.estatusTarea == 1);
        console.log("temp1: "+temp1.length)
        setlistaDeTareasProceso(temp1);

        const temp2 = response.data.filter((elemento) => elemento.estatusTarea == 2);
        console.log("temp2: "+temp2.length)
        setlistaDeTareasTerminadas(temp2);

        if(response.data.length > 1){
          console.log("ordenando tareas ");
          const arr1 = response.data.map(obj => {
            return {...obj, fechaCreacion: new Date(obj.fechaCreacion)};
          });
          console.log("arr1:"+arr1[0].fechaCreacion)
          const sortedDesc = arr1.sort(
            (objA, objB) => Number(objB.fechaCreacion) - Number(objA.fechaCreacion),
          );
          console.log("sortedDesc:"+sortedDesc[0].fechaCreacion)

          var descSliced = sortedDesc.slice(0, 5)

          console.log("descSliced:"+descSliced)

            setlistaDeTareasDescendientes(descSliced);
          
        }

        // Create array of 12 items init'd at 0
        const monthCountArr = new Array(12).fill(0);
        const monthCountArrTerminadas = new Array(12).fill(0);
        const actual = new Date().getFullYear();
        console.log("año actual:"+actual)
        // increment the count of each month
        response.data.map(
          item => {
            if (new Date(item.fechaCreacion).getFullYear() == actual){
              monthCountArr[new Date(item.fechaCreacion).getMonth()] += 1
              console.log("item.createdAt "+ new Date(item.fechaCreacion).getMonth())
              if(item.estatusTarea == 2){
                monthCountArrTerminadas[new Date(item.fechaCreacion).getMonth()] += 1
              }
            }
            
          }
        );
        //setnumDeLiberaciones(monthCountArr);
        console.log(monthCountArr);
        const series = [
          {
            name: "Tareas totales para el proyecto",
            type: "column",
            data: monthCountArr,
          },
          {
            name: "Tareas terminadas",
            type: "line",
            data: monthCountArrTerminadas,
          },
          
        ]; 
        setnumDeSeries(series);

      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })
    .catch(err => console.log(err))
    }, [])

  const recentTasks = tasks.find(task => task.title === "Recent Tasks");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tareas" breadcrumbItem="Lista de Tareas" />
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Pendientes</h4>
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No.</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Actividad</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Descripción</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Takt</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Responsable</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Estatus</h5>
                          </td>
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(listaDeTareasPendientes, (tarea, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{tarea.nombreTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.descripcionTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.noNivel}</label>
                              </td>
                              <td>
                                <label>{tarea.noTakt}</label>
                              </td>
                              <td>
                                <label>{tarea.idUsuarioConTarea}</label>
                              </td>
                              <td>
                              <div className="text-center">
                                <span className="badge rounded-pill badge-soft-secondary font-size-11">Pendiente</span>
                              </div>
                              </td>
                          
                            </tr>
                        ))
                      }
                        {/** 
                        <tr>
                          <td style={{ width: "40px" }}>
                            <div className="form-check font-size-16">
                              <input className="form-check-input" type="checkbox" id="upcomingtaskCheck01" />
                              <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">Create a Skote Dashboard UI</Link></h5>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <div className="avatar-group-item">
                                <Link to="#" className="d-inline-block">
                                  <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                </Link>
                              </div>
                              <div className="avatar-group-item">
                                <Link to="#" className="d-inline-block">
                                  <img src={avatar5} alt="" className="rounded-circle avatar-xs" />
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-center">
                              <span className="badge rounded-pill badge-soft-secondary font-size-11">Waiting</span>
                            </div>
                          </td>
                        </tr>

                          */}

                        
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">En Proceso</h4>
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                      <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No.</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Actividad</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Descripción</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Takt</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Responsable</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Estatus</h5>
                          </td>
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(listaDeTareasProceso, (tarea, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{tarea.nombreTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.descripcionTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.noNivel}</label>
                              </td>
                              <td>
                                <label>{tarea.noTakt}</label>
                              </td>
                              <td>
                                <label>{tarea.idUsuarioConTarea}</label>
                              </td>
                              <td>
                                <div className="text-center">
                                  <span className="badge rounded-pill badge-soft-warning font-size-11">Proceso</span>
                                </div>
                              </td>
                          
                            </tr>
                        ))
                      }
                        
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Terminadas</h4>
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                      <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No.</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Actividad</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Descripción</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Takt</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Responsable</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Estatus</h5>
                          </td>
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(listaDeTareasTerminadas, (tarea, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{tarea.nombreTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.descripcionTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.noNivel}</label>
                              </td>
                              <td>
                                <label>{tarea.noTakt}</label>
                              </td>
                              <td>
                                <label>{tarea.idUsuarioConTarea}</label>
                              </td>
                              <td>
                                <div className="text-center">
                                <span className="badge rounded-pill badge-soft-success font-size-11">Terminada</span>
                                </div>
                              </td>
                          
                            </tr>
                        ))
                      }
                        

                        
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Tareas </CardTitle>
                  <ReactApexChart
                    options={options}
                    series={numDeSeries}
                    type="line"
                    height={280}
                    className="apex-charts"
                  />
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Tareas Recientes</h4>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                      <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No.</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Actividad</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Descripción</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Takt</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Responsable</h5>
                          </td>
                          
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(listaDeTareasDescendientes, (tarea, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{tarea.nombreTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.descripcionTarea}</label>
                              </td>
                              <td>
                                <label>{tarea.noNivel}</label>
                              </td>
                              <td>
                                <label>{tarea.noTakt}</label>
                              </td>
                              <td>
                                <label>{tarea.idUsuarioConTarea}</label>
                              </td>
                              
                          
                            </tr>
                        ))
                      }
                        
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(TasksList);

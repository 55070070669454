import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//apex radial chart
import RadialChart from "../AllCharts/apex/RadialChart"


//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { isEmpty, map } from "lodash";

import {
  getInspecciones,
} from "../../store/actions";

const _ = require('lodash');

const Dashboard = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const { project } = useSelector(state => ({
    project: state.projects.project,
  }));

  //const [modal_standard, setmodal_standard] = useState(false);
  var modal_standard = false;

  if (_.isEmpty(project) ) {
    //navigate("/inicio");
    //return null;
    tog_standard();
  }
  
  function tog_standard() {
    modal_standard=!modal_standard;
    console.log(modal_standard);
    //removeBodyCss();
  }

  //const { fromHome } = location.state;
  let infoProyecto = project;
  //console.log(ProyectoSeleccionado.state)

  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const[listaDeLiberaciones, setlistaDeLiberaciones] = useState([]);

  const[numDeSeries, setnumDeSeries] = useState([]);

  const[liberacionesDescendientes, setliberacionesDescendientes] = useState([]);

  const[listaDeTareas, setlistaDeTareas] = useState([]);

  const[listaDeTareasPendientes, setlistaDeTareasPendientes] = useState([]);

  const[listaDeTareasProceso, setlistaDeTareasProceso] = useState([]);

  const[listaDeTareasTerminadas, setlistaDeTareasTerminadas] = useState([]);

  const[listaDeTareasDescendientes, setlistaDeTareasDescendientes] = useState([]);

 // const [meses, setmeses] = useState(["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]);

  const[listaDeInspecciones, setListaDeInspecciones] = useState([]);


  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

 
  


  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/inspecciones/'+project.id)
    .then((response) => {
      if(isEmpty(response.data)){

        alert("No se han creado tipos de inspecciones, favor de crearlos.")
        /*
        console.log("array de inspecciones vacío")
        tog_standard();
        tog_bool();
        
        for(var i=1; i<=proyecto.noInspecciones; i++){
          var inspect = {key: i, inspeccion:"Inspección #"+i}
          provisional.push(inspect)
          
        }
        
        setsin_inspecciones(provisional);
        console.log("sin_inspecciones:"+sin_inspecciones)
        */
        
      }
      else{
        dispatch(getInspecciones(response.data));
        setListaDeInspecciones(response.data);
        console.log("response.data de inspecciones:"+response.data)
      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })
    .catch(err => console.log(err))
    }, [])

  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/listaLiberaciones/'+project.id)
    .then((response) => {
      if(isEmpty(response.data)){
        console.log("array de inspecciones vacío")
        //tog_standard();
        //tog_bool();
        
        
        
      }
      else{
        //dispatch(getInspecciones(response.data));
        console.log("listaDeInspecciones:"+listaDeInspecciones);
        setlistaDeLiberaciones(response.data);
        console.log("response.data:"+response.data)
        // Create array of 12 items init'd at 0
        const monthCountArr = new Array(12).fill(0);
        const actual = new Date().getFullYear();
        console.log("año actual:"+actual)
        // increment the count of each month
        response.data.map(
          item => {
            if (new Date(item.createdAt).getFullYear() == actual){
              monthCountArr[new Date(item.createdAt).getMonth()] += 1
              console.log("item.createdAt "+ new Date(item.createdAt).getMonth())
            }
            
          }
        );
        //setnumDeLiberaciones(monthCountArr);
        console.log(monthCountArr);
        const series = [
          {
            name: "Inspecciones completadas",
            type: "column",
            data: monthCountArr,
          },
          
        ]; 
        setnumDeSeries(series);



        setliberacionesDescendientes(listaDeLiberaciones);

        console.log("cantidad de items en lista:"+response.data.length)

        if(response.data.length > 1){
          console.log("ordenando liberaciones ");
          const arr1 = response.data.map(obj => {
            return {...obj, createdAt: new Date(obj.createdAt)};
          });
          console.log("arr1:"+arr1[0].createdAt)
          const sortedDesc = arr1.sort(
            (objA, objB) => Number(objB.createdAt) - Number(objA.createdAt),
          );
          console.log("sortedDesc:"+sortedDesc[0].createdAt)
            setliberacionesDescendientes(sortedDesc);
          
        }
        



      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })  
    .catch(err => console.log(err))
    }, [])

    useEffect(() => {
      axios.get('https://servidorpruebapp.itnmexico.com/tareas/'+project.id)
      .then((response) => {
        if(isEmpty(response.data)){
          console.log("array de inspecciones vacío");
          alert("No existen tareas para este proyecto, favor de crearlas.");
          //tog_standard();
          //tog_bool();
          
          
        }
        else{
          //dispatch(getInspecciones(response.data));
          setlistaDeTareas(response.data);
          console.log("response.data: "+response.data)
          const temp = response.data.filter((elemento) => elemento.estatusTarea == 0);
          console.log("temp: "+temp.length)
          setlistaDeTareasPendientes(temp);
  
          const temp1 = response.data.filter((elemento) => elemento.estatusTarea == 1);
          console.log("temp1: "+temp1.length)
          setlistaDeTareasProceso(temp1);
  
          const temp2 = response.data.filter((elemento) => elemento.estatusTarea == 2);
          console.log("temp2: "+temp2.length)
          setlistaDeTareasTerminadas(temp2);
  
          if(response.data.length > 1){
            console.log("ordenando tareas ");
            const arr1 = response.data.map(obj => {
              return {...obj, fechaCreacion: new Date(obj.fechaCreacion)};
            });
            console.log("arr1:"+arr1[0].fechaCreacion)
            const sortedDesc = arr1.sort(
              (objA, objB) => Number(objB.fechaCreacion) - Number(objA.fechaCreacion),
            );
            console.log("sortedDesc:"+sortedDesc[0].fechaCreacion)
  
            var descSliced = sortedDesc.slice(0, 5)
  
            console.log("descSliced:"+descSliced)
  
              setlistaDeTareasDescendientes(descSliced);
            
          }
  
          // Create array of 12 items init'd at 0
          const monthCountArr = new Array(12).fill(0);
          const monthCountArrTerminadas = new Array(12).fill(0);
          const actual = new Date().getFullYear();
          console.log("año actual:"+actual)
          // increment the count of each month
          response.data.map(
            item => {
              if (new Date(item.fechaCreacion).getFullYear() == actual){
                monthCountArr[new Date(item.fechaCreacion).getMonth()] += 1
                console.log("item.createdAt "+ new Date(item.fechaCreacion).getMonth())
                if(item.estatusTarea == 2){
                  monthCountArrTerminadas[new Date(item.fechaCreacion).getMonth()] += 1
                }
              }
              
            }
          );
          //setnumDeLiberaciones(monthCountArr);
          console.log(monthCountArr);
          const series = [
            {
              name: "Tareas totales para el proyecto",
              type: "column",
              data: monthCountArr,
            },
            {
              name: "Tareas terminadas",
              type: "line",
              data: monthCountArrTerminadas,
            },
            
          ]; 
          setnumDeSeries(series);
  
        }
          //arrancar modal
          //desplegar el no. de inspecciones definidas en crearproyecto
  
        
        
      })
      .catch(err => console.log(err))
      }, [])

  //meta title
  document.title = "Dashboard de Proyectos";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={infoProyecto.nombre}
          />
          
          <Row>
             
            <Col xl="8">
              <WelcomeComp paraWelcome={infoProyecto} listaLiberaciones={listaDeLiberaciones} tareasPendientes={listaDeTareasPendientes}/>
              {/*<MonthlyEarning />*/}
            </Col>
            
            <Col xl="8">
              {/*
              <Row>
                {/* Reports Render }
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

                              */}

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Inspecciones de calidad realizadas</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          
                                {/** 
                          
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">

                            */}

                          
                            Información Anual
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} dataColors='["--bs-blue", "--bs-purple", "--bs-pink", "--bs-orange", "--bs-yellow", "--bs-green", "--bs-teal", "--bs-cyan", "--bs-gray", "--bs-gray-dark"]' listaDeInspecciones={listaDeInspecciones} listaDeLiberaciones={listaDeLiberaciones} />
                  {console.log("ListaDeInspecciones:"+listaDeInspecciones)}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Porcentaje de procesos completados</CardTitle>
                  <RadialChart dataColors='["--bs-blue", "--bs-purple", "--bs-pink", "--bs-orange", "--bs-yellow", "--bs-green", "--bs-teal", "--bs-cyan", "--bs-gray", "--bs-gray-dark"]' listaDeInspecciones={listaDeInspecciones} listaDeLiberaciones={listaDeLiberaciones} proyecto={project}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                      tog_standard();
                                    }}
                                  >
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0" id="myModalLabel">
                                        Alerta
                                      </h5>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          modal_standard=false;
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <h5>Favor de seleccionar un proyecto en la página de inicio.</h5>
                                      
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          //tog_standard();
                                          navigate("/inicio");
                                        }}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                      >
                                        Cerrar
                                      </button>
                                      
                                    </div>
                                  </Modal>
          {/*
          <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row>
          */}
          {/* 
          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>
          */}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* 
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      */}
      {/* 
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      */}
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);

import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

import { isEmpty, map } from "lodash";

const StackedColumnChart = ({ dataColors, periodData, listaDeInspecciones, listaDeLiberaciones }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors);

  const infoAnual = [];

  if(isEmpty(listaDeInspecciones)){
    console.log("lista de inspecciones vacía")
    let prueba = {
      "name": "Sin inspecciones",
      "data": [0,0]
    }
    infoAnual.push(prueba);
  }
  else{
    if(isEmpty(listaDeLiberaciones)){
      console.log("lista de liberaciones vacía")
    }
    else{
      console.log("adentro de stacked")
      const monthCountArr = new Array(12).fill(0);
      var mesCuentaArr = new Array(listaDeInspecciones.length);
      for(var i=0;i<listaDeInspecciones.length;i++){
        mesCuentaArr[i] = new Array(12).fill(0);
      }
      
      const actual = new Date().getFullYear();
      listaDeInspecciones.map(
        (tipoDeInspeccion, key) => {
          listaDeLiberaciones.map(
            (liberacion,key2) => {
              if (new Date(liberacion.createdAt).getFullYear() == actual){
                if(tipoDeInspeccion.nombreInspeccion == liberacion.nombreInspeccion){
                  console.log("key:"+key);
                  console.log("key2:"+key2);
                  mesCuentaArr[key][new Date(liberacion.createdAt).getMonth()] +=1;
                  monthCountArr[new Date(liberacion.createdAt).getMonth()] += 1
                  console.log("item.createdAt "+ new Date(liberacion.createdAt).getMonth())
                  console.log("mescuentaarr:"+mesCuentaArr);
                  console.log("mescuentaarr[0,8]"+mesCuentaArr[0][8])
                }
              }
            }
          );
        }
      );
        
        listaDeInspecciones.map(
          (tipoDeInspeccion, key) => {
            let prueba = {
              "name": tipoDeInspeccion.nombreInspeccion,
              "data": mesCuentaArr[key].slice(0,12)
            }
            infoAnual.push(prueba);
            //infoAnual[key].name = tipoDeInspeccion.nombreInspeccion;
            //infoAnual[key].data = mesCuentaArr[key].slice(0,12);
          }
        );
        

    }
  }


  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ],
      labels: {
        show: true
      }
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }

  const yearData = [
    {
        name: "listaDeInspecciones[0].nombreInspeccion",
        data: [33, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
    },
    {
        name: "Series B",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
    },
    {
        name: "Series C",
        data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
    },
];

console.log("yearData:"+yearData[0].name);

console.log("infoAnual:"+infoAnual);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={infoAnual}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart;

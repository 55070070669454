import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  
} from "reactstrap";

import { isEmpty, map } from "lodash";

import * as Yup from "yup";
import { useFormik } from "formik";

import moment from"moment";

import axios from "axios";

import Select from "react-select";

import {

  getInspecciones,

} from "../../store/actions";

// Import Editor
//import { Editor } from "react-draft-wysiwyg";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useSelector, useDispatch } from "react-redux";

const CrearInspecciones = () => {

  const dispatch = useDispatch();
  const[listaDeProyectos, setListaDeProyectos] = useState([]);

  //meta title
  document.title="Crear Inspecciones para el Proyecto";

  const inpRow = [{ name: "", file: "" }]
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [inputFields, setinputFields] = useState(inpRow)

  const startDateChange = date => {
    var newdate = moment(date).format('YYYY-MM-DD')
    setstartDate(date)
    console.log("fecha:"+newdate)
  }

  const seleccionarInspeccion = inspeccion => {
    const result = inspecciones.find(({ nombreInspeccion }) => nombreInspeccion === inspeccion);
    console.log("result.contcampo1:"+result.contenidoCampo1)
    setInspeccionSeleccionada(result);

  }

  const endDateChange = date => {
    setendDate(date)
  }

  const[modal_standard, setmodal_standard] = useState(false);
  const[bool_inspecciones, setbool_inspecciones] = useState(false);

  const[inspeccionSeleccionada, setInspeccionSeleccionada] = useState();




  // Function for Create Input Fields
  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    document.getElementById("nested" + idx).style.display = "none"
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nombreInspeccion:  "",
      noTakt: "",
      noNivel: "",
    },
    /*
    validationSchema: Yup.object({
      nombreInspeccion: Yup.string().required(
        "Por favor ingrese un nombre para la tarea"
      ),
      noTakt: Yup.string().required(
        "Por favor ingrese un número de Takt"
      ),
      noNivel: Yup.string().required(
        "Por favor ingrese un número de Nivel"
      ),
    }),
    */
    onSubmit: (values) => {
      console.log("entrando a onsubmit")


        if(isEmpty(fileName)){
          console.log("no ha seleccionado archivo:"+fileName);
          alert("Favor de seleccionar un archivo.");
          
        }
        else{
          if(values.noNivel == "Selecciona la opción deseada..." || values.noTakt == "Selecciona la opción deseada..." || inspeccionSeleccionada.nombreInspeccion === undefined){
            alert("Favor de seleccionar la información deseada.");
            console.log("values.noNivel:"+values.noNivel);
            console.log("values.noTakt:"+values.noTakt);
            console.log("values.nombreInspeccion:"+values.nombreInspeccion);
          }
          else{
            console.log("empezando a grabar archivo")
        
        //dispatch(onAddNewProject(newProject));

        console.log("empezando a grabar archivo")
        console.log("nombre de archivo en state:"+fileName)
        console.log("contenido de archivo en state:"+file)
        console.log("value.nombreDeInspeccion:"+values.nombreInspeccion)
        console.log("value.noNivel:"+values.noNivel)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("nombreInspeccion", inspeccionSeleccionada.nombreInspeccion);
        formData.append("noNivel", values.noNivel);
        formData.append("noTakt", values.noTakt);
        formData.append("idProyecto", proyecto.id);
        formData.append("campo1", inspeccionSeleccionada.campo1);
        formData.append("contenidoCampo1", inspeccionSeleccionada.contenidoCampo1);

        

        console.log("contenido de formData:"+formData)

        axios.post("https://servidorpruebapp.itnmexico.com/listaLiberaciones/",
        formData  
        )
      .then((response) => {
        
        console.log("esta es la respuesta: "+response);
        alert("Se ha guardado la inspección exitosamente")
        
 
      })
      .catch(err => console.log(err))
    }
        //setFileName("");
        //console.log("filename:"+fileName);
          }
        
      
    },
  });

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));
  const { proyecto } = useSelector(state => ({
    proyecto: state.projects.project,
  }));
  const { inspecciones } = useSelector(state => ({
    inspecciones: state.projects.inspecciones,
  }));

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const[sin_inspecciones, setsin_inspecciones] = useState([]);
  var  provisional = [];



  function tog_standard() {
    setmodal_standard(!modal_standard);
    console.log("modal_standard:"+modal_standard);
  }

  function tog_bool() {
    setbool_inspecciones(!bool_inspecciones);
    console.log("bool_inspecciones:" + bool_inspecciones);
  }

  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/inspecciones/'+proyecto.id)
    .then((response) => {
      if(isEmpty(response.data)){
        console.log("array de inspecciones vacío")
        tog_standard();
        tog_bool();
        
        setsin_inspecciones(provisional);
        console.log("sin_inspecciones:"+sin_inspecciones)
        dispatch(getInspecciones(provisional));
        
      }
      else{
        dispatch(getInspecciones(response.data));
        setListaDeProyectos(response.data);
        console.log("response.data:"+response.data)
      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })
    .catch(err => console.log(err))
    }, [])


  const saveFile = (e) => {
    console.log("saving file")
    if(e.target.files[0]){
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      console.log("nombre de archivo en state: "+fileName)
      console.log("e.target"+e.target.files[0].name)
    }
    else{
      alert("No se ha seleccionado un archivo")
    }
  };


  const temp = [...Array(proyecto.noNiveles)];
  const temp2 = [...Array(proyecto.noTakts)];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
              }}
            >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                                        Alerta
              </h5>
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
              </div>
            <div className="modal-body">
                <h5>No se han definido tipos inspecciones para este proyecto, para usar esta sección favor de añadir tipos de inspecciones primero.</h5>
                                      
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          tog_standard();
                                          //navigate("/inicio");
                                        }}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                      >
                                        Cerrar
                                      </button>
                                      
                                    </div>
                                  </Modal>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Inspecciones" breadcrumbItem="Crear Inspección" />
          {console.log("inspecciones:"+inspecciones)}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Crear Nueva Inspección</CardTitle>
                  <Form
                        className="outer-repeater"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                  {/** <form className="outer-repeater">*/}
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Nombre de la Inspección
                          </Label>
                          <Col lg="10">
                          
                            <select 
                              name="nombreInspeccion"
                              className="form-select"
                              value={inspeccionSeleccionada ? inspeccionSeleccionada.nombreInspeccion : "" }
                              onChange={
                                e => {
                                  seleccionarInspeccion(e.target.value);
                                  validation.handleChange
                                }
                              }
                              >
                              <option disabled={true} value="">--Selecciona una opción--</option>
                               
                                {inspecciones.map((inspeccion, key) => (
                                  <option key={key}
                                    value={inspeccion.nombreInspeccion}
                                  >{inspeccion.nombreInspeccion}</option> 
                                  
                                )
                                  )}
                              </select>
                              {validation.touched.nombreInspeccion && validation.errors.nombreInspeccion ? (
                                <FormFeedback type="invalid">{validation.errors.nombreInspeccion}</FormFeedback>
                              ) : null}

                                {/**
                            <Input
                                name="nombreInspeccion"
                                id="nombreInspeccion"
                                type="select"
                                className="form-select"
                                
                                onChange={
                                  validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.nombreInspeccion || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                {inspecciones.map((inspeccion, key) => (
                                  <option key={key}
                                  
                                  >{inspeccion.nombreInspeccion}</option> 
                                  
                                )
                                  )}
                                
                              </Input>
                              {validation.touched.nombreInspeccion && validation.errors.nombreInspeccion ? (
                                <FormFeedback type="invalid">{validation.errors.nombreInspeccion}</FormFeedback>
                              ) : null}
                               */}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="campo1"
                            className="col-form-label col-lg-2"
                          >
                            Parámetro Inspeccionado
                          </Label>
                          <Col lg="10">
                          <Label
                            htmlFor="campo1"
                            className="col-form-label col-lg-2"
                          >
                            {inspeccionSeleccionada ? inspeccionSeleccionada.campo1 : ""}
                          </Label>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Selecciona el No. de Nivel
                          </Label>
                          <Col lg="10">
                            <Input
                                name="noNivel"
                                id="noNivel"
                                type="select"
                                className="form-select"
                                
                                onChange={
                                  validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.noNivel || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                {console.log("temp:"+temp.length)}
                                {
                                  
                                  temp.map((nivel, key) => (
                                    <option key={key}>
                                      {key+1}
                                  </option> 
                                
                                  )
                                  )
                                };
                                
                                
                              </Input>
                              {validation.touched.noNivel && validation.errors.noNivel ? (
                                <FormFeedback type="invalid">{validation.errors.noNivel}</FormFeedback>
                              ) : null}

                            
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Selecciona el No. de Takt
                          </Label>
                            <Col lg="10">
                              <Input
                                name="noTakt"
                                id="noTakt"
                                type="select"
                                className="form-select"
                                
                                onChange={
                                  validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.noTakt || ""
                                }
                              >
                                <option>Selecciona la opción deseada...</option>
                                {console.log("temp2:"+temp2.length)}
                                {
                                  
                                  temp2.map((nivel, key) => (
                                    <option key={key}>
                                      {key+1}
                                  </option> 
                                
                                  )
                                  )
                                };
                                
                                
                              </Input>
                              {validation.touched.noTakt && validation.errors.noTakt ? (
                                <FormFeedback type="invalid">{validation.errors.noTakt}</FormFeedback>
                              ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                              Agrega un archivo de respaldo
                            </Label>
                          <div className="col-lg-10">
                          <Input
                                        id="file" 
                                        name="file"
                                        type="file"
                                        className="form-control"
                                        defaultValue=""
                                        onChange={saveFile}
                                      />
                          </div>
                        </FormGroup>


{/** 


                        <div className="inner-repeater mb-4">
                          <div className="inner form-group mb-0 row">
                            <Label className="col-form-label col-lg-2">
                              Agrega un miembro del Equipo
                            </Label>
                            <div
                              className="inner col-lg-10 ml-md-auto"
                              id="repeater"
                            >
                              {inputFields.map((field, key) => (
                                <div
                                  key={key}
                                  id={"nested" + key}
                                  className="mb-3 row align-items-center"
                                >
                                  <Col md="6">
                                    <input
                                      type="text"
                                      className="inner form-control"
                                      defaultValue={field.name}
                                      placeholder="Ingrese el nombre"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <div className="mt-4 mt-md-0">
                                      <Input
                                        type="file"
                                        className="form-control"
                                        defaultValue={field.file}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="2">
                                    <div className="mt-2 mt-md-0 d-grid">
                                      <Button
                                        color="primary"
                                        className="inner"
                                        onClick={() => {
                                          handleRemoveFields(key)
                                        }}
                                        block
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </Col>
                                </div>
                              ))}
                            </div>
                          </div>
                          <Row className="justify-content-end">
                            <Col lg="10">
                              <Button
                                color="success"
                                className="inner"
                                onClick={() => {
                                  handleAddFields()
                                }}
                              >
                                Add Number
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Budget
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>


*/}



                      </div>
                    </div>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                        Crear Inspección
                        </Button>
                      </Col>
                    </Row>
                  {/** </form>*/}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CrearInspecciones

import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Badge,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import images from "assets/images";
import companies from "assets/images/companies";

import { seleccionarProyecto } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from 'axios';

import {

  getInspecciones,

} from "../../store/actions";


const CardProject = ({ listaDeProyectos }) => {
  const navigate = useNavigate();
  //const data = { name: "John", age: 30 };
  const [ProyectoSeleccionado, setProyectoSeleccionado] = useState()

  const dispatch = useDispatch();

 const handleClick = useCallback( (project) => {

    setProyectoSeleccionado(project)
    dispatch(seleccionarProyecto(project))

      axios.get('https://servidorpruebapp.itnmexico.com/inspecciones/'+project.id)
      .then((response) => {
        if(isEmpty(response.data)){
          var provisional = [];
          console.log("array de inspecciones vacío")
          dispatch(getInspecciones(provisional));
          
        }
        else{
          dispatch(getInspecciones(response.data));
          console.log("response.data:"+response.data)

        }

  
        
        
      })
      .catch(err => console.log(err))
      }, [])

  


  return (
    <React.Fragment>
      {map(listaDeProyectos, (project, key) => (
        
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
            {console.log(project)}
              <div className="d-flex">
                <div className="avatar-md me-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img src="https://servidorpruebapp.itnmexico.com/archivos/proyectos/Up-Santa-Fe.jpeg" alt="" height="30" />
                  </span>
                  </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <Link
                      //to={`/projects-overview/${project.id}`}
                      to="/dashboard" state={{ fromHome: { project } }}
                      /*
                        pathname: `/dashboard`,
                        state: {id: 1, name: 'Aaron'}
                      }}
                      */
                     onClick={() => handleClick(project)}
                      
       
                      className="text-dark"
                    >
                      {project.nombre}
                    </Link>
                  </h5>
                  <p className="text-muted mb-4">{project.ubicacion}</p>
                  {/* 
                  <div className="avatar-group">
                    {project.team.map((team, key) =>
                      !team.img || team.img !== "Null" ? (
                        <React.Fragment key={key}>
                          <div className="avatar-group-item">
                            <Link
                              to="#"
                              className="d-inline-block"
                              id={"member" + key}
                            >
                              <img
                                src={images[team.img]}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={"member" + key}
                              >
                                {team.fullname}
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={key}>
                          <div className="avatar-group-item">
                            <Link
                              to="#"
                              className="d-inline-block"
                              id={"member" + key}
                            >
                              <div className="avatar-xs">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-" +
                                    team.color +
                                    " text-white" +                                   
                                    " font-size-16"
                                  }
                                >
                                  {team.name}
                                </span>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"member" + key}
                                >
                                  {team.fullname}
                                </UncontrolledTooltip>
                              </div>
                            </Link>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>*/}
                </div>
              </div>
            </CardBody>
            
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  <Badge className={"bg-" + project.color}>
                    {project.status}
                  </Badge>
                </li>{" "}
                <li className="list-inline-item me-3" id="dueDate">
                  <i className="bx bx-calendar me-1" /> {project.dueDate}
                  <UncontrolledTooltip placement="top" target="dueDate">
                    Due Date
                  </UncontrolledTooltip>
                </li>{" "}
                <li className="list-inline-item me-3" id="comments">
                  <i className="bx bx-comment-dots me-1" />{" "}
                  {project.commentsCount}
                  <UncontrolledTooltip placement="top" target="comments">
                    Comments
                  </UncontrolledTooltip>
                </li>
              </ul>
            </div>
            
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardProject.propTypes = {
  listaDeProyectos: PropTypes.array,
};

export default CardProject;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, CardTitle } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { isEmpty, map } from "lodash";

//Import Images
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import { getTasks as onGetTasks } from "../../store/tasks/actions";
import { options, series, } from "common/data/tasks";

//redux
import { useSelector, useDispatch } from "react-redux";

const ListaInspecciones = () => {
  //meta title
  document.title = "Lista de inspecciones para el proyecto";

  const dispatch = useDispatch();

  const { tasks } = useSelector(state => ({
    tasks: state.tasks.tasks,
  }));

  useEffect(() => {
    dispatch(onGetTasks());
  }, [dispatch]);

  const { proyecto } = useSelector(state => ({
    proyecto: state.projects.project,
  }));

  const[bool_inspecciones, setbool_inspecciones] = useState(false);

  const[listaDeLiberaciones, setlistaDeLiberaciones] = useState([]);

  const[liberacionesDescendientes, setliberacionesDescendientes] = useState([]);

  //const[numDeLiberaciones, setnumDeLiberaciones] = useState([]);

  const[numDeSeries, setnumDeSeries] = useState([]);

  function tog_bool() {
    setbool_inspecciones(!bool_inspecciones);
    console.log("bool_inspecciones:" + bool_inspecciones);
  }

  console.log("proyecto.id:"+proyecto.id);

  useEffect(() => {
    axios.get('https://servidorpruebapp.itnmexico.com/listaLiberaciones/'+proyecto.id)
    .then((response) => {
      if(isEmpty(response.data)){
        console.log("array de inspecciones vacío")
        //tog_standard();
        tog_bool();
        
        
        
      }
      else{
        //dispatch(getInspecciones(response.data));
        setlistaDeLiberaciones(response.data);
        console.log("response.data:"+listaDeLiberaciones)
        // Create array of 12 items init'd at 0
        const monthCountArr = new Array(12).fill(0);
        const actual = new Date().getFullYear();
        console.log("año actual:"+actual)
        // increment the count of each month
        response.data.map(
          item => {
            if (new Date(item.createdAt).getFullYear() == actual){
              monthCountArr[new Date(item.createdAt).getMonth()] += 1
              console.log("item.createdAt "+ new Date(item.createdAt).getMonth())
            }
            
          }
        );
        //setnumDeLiberaciones(monthCountArr);
        console.log(monthCountArr);
        const series = [
          {
            name: "Inspecciones completadas",
            type: "column",
            data: monthCountArr,
          },
          
        ]; 
        setnumDeSeries(series);



        setliberacionesDescendientes(listaDeLiberaciones);

        console.log("cantidad de items en lista:"+response.data.length)

        if(response.data.length > 1){
          console.log("ordenando liberaciones ");
          const arr1 = response.data.map(obj => {
            return {...obj, createdAt: new Date(obj.createdAt)};
          });
          console.log("arr1:"+arr1[0].createdAt)
          const sortedDesc = arr1.sort(
            (objA, objB) => Number(objB.createdAt) - Number(objA.createdAt),
          );
          console.log("sortedDesc:"+sortedDesc[0].createdAt)
            setliberacionesDescendientes(sortedDesc);
          
        }
        



      }
        //arrancar modal
        //desplegar el no. de inspecciones definidas en crearproyecto

      
      
    })  
    .catch(err => console.log(err))
    }, [])


  const recentTasks = tasks.find(task => task.title === "Recent Tasks");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Inspecciones" breadcrumbItem="Lista de Inspecciones" />
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Completadas</h4>
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                        <tr>
                          <td style={{ width: "40px" }}>
                            <h5 className="text-truncate font-size-14 m-0">#</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No. de Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No. de Takt</h5>
                          </td>
                          <td>
                          <h5 className="text-truncate font-size-14 m-0">Nombre de Inspección</h5>
                          </td>
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(listaDeLiberaciones, (liberacion, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{liberacion.noNivel}</label>
                              </td>
                              <td>
                                <label>{liberacion.noTakt}</label>
                              </td>
                              <td>
                                <label>{liberacion.nombreInspeccion}</label>
                              </td>
                          
                            </tr>
                        ))
                      }

                        
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

              
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Inspecciones</CardTitle>
                  <ReactApexChart
                    options={options}
                    series={numDeSeries}
                    type="line"
                    height={280}
                    className="apex-charts"
                  />
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Inspecciones Recientes</h4>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                      <tr>
                          <td style={{ width: "40px" }}>
                            <h5 className="text-truncate font-size-14 m-0">#</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No. de Nivel</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">No. de Takt</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Nombre de Inspección</h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">Fecha de elaboración</h5>
                          </td>
                        </tr>
                        { bool_inspecciones 
                          ? <tr><td></td><label>No hay inspecciones por mostrar</label></tr>
                          : map(liberacionesDescendientes, (liberacion, index) => ( 
                            <tr key={index}>
                              <td>
                                <label>{index+1}</label>
                              </td>
                              <td>
                                <label>{liberacion.noNivel}</label>
                              </td>
                              <td>
                                <label>{liberacion.noTakt}</label>
                              </td>
                              <td>
                                <label>{liberacion.nombreInspeccion}</label>
                              </td>
                              <td>
                                <label>{liberacion.createdAt.toLocaleDateString()}</label>
                              </td>
                            </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ListaInspecciones);

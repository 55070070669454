import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

import { isEmpty, map } from "lodash";

const RadialChart = ({ dataColors, listaDeInspecciones, listaDeLiberaciones, proyecto }) => {
  const radialChartColors = getChartColorsArray(dataColors);

  const totalInspecciones = proyecto.noTakts*proyecto.noNiveles*listaDeInspecciones.length;
  console.log("totalInspecciones:"+totalInspecciones);

  const numLiberaciones = new Array(listaDeInspecciones.length).fill(0);
  const nombreLiberaciones = new Array(listaDeInspecciones.length).fill("");
  const porcLiberaciones = new Array(listaDeInspecciones.length).fill(0);
  var totalLiberaciones = 0;

  if(isEmpty(listaDeInspecciones)){
    console.log("lista de inspecciones vacía")
    
  }
  else{
    if(isEmpty(listaDeLiberaciones)){
      console.log("lista de liberaciones vacía")
    }
    else{
      console.log("adentro de radial")
      listaDeInspecciones.map(
        (tipoDeInspeccion, key) => {
          nombreLiberaciones[key] = tipoDeInspeccion.nombreInspeccion;
          listaDeLiberaciones.map(
            (liberacion,key2) => {
                if(tipoDeInspeccion.nombreInspeccion == liberacion.nombreInspeccion){
                  console.log("key:"+key);
                  console.log("key2:"+key2);
                  numLiberaciones[key] += 1;
                  
                }
              
            }
          );
        }
      );
      numLiberaciones.map(
        (lib, key) => {
          porcLiberaciones[key] = lib / (proyecto.noTakts*proyecto.noNiveles) * 100;
        }
      );

      porcLiberaciones.map(
        (lib,key) => {
          totalLiberaciones = totalLiberaciones+lib;
        }
      );

      totalLiberaciones = totalLiberaciones/listaDeInspecciones.length;
        

        
    }
  }
  console.log("porcLib:"+porcLiberaciones);

  const series = porcLiberaciones;
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Promedio Total",
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              var result = ''.concat(totalLiberaciones,"%");
              return result
            },
          },
        },
      },
    },

    labels: nombreLiberaciones,
    colors: radialChartColors,
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="370"
      className="apex-charts"
    />
  )
}

export default RadialChart

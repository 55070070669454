import React, { useEffect, useState } from "react";

import axios from 'axios';

import {
  Col,
  Container,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

import withRouter from "components/Common/withRouter";
import { map } from "lodash";

//Import Breadcrumb 
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards
import CardProject from "./card-project";

//import { getProjects as onGetProjects } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";



const Inicio = props => {

  //meta title
  document.title = "Proyectos con ITN de Mexico";

  //const dispatch = useDispatch();

  /*const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));
  */

     const { user } = useSelector(state => ({
    user: state.Login.user,
  })); 

 // const user = useSelector((state) => state.user);


  //console.log("user:"+user.tipoUsuario)
  
 const[listaDeProyectos, setListaDeProyectos] = useState([]);


 useEffect(() => {
  axios.get("https://servidorpruebapp.itnmexico.com/proyectos/")
  .then((response) => {
    setListaDeProyectos(response.data);
    console.log("response.data")
    console.log(response.data)
  })
  .catch(err => console.log(err))
  }, [])

  const [page, setPage] = useState(1);
  const [totalPage] = useState(5);

  /*useEffect(() => {
    dispatch(onGetProjects());
  }, [dispatch]);
  */
  const handlePageClick = page => {
    setPage(page);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Proyectos" />

          <Row>
            {/* Import Cards */}
            <CardProject listaDeProyectos={listaDeProyectos} />
          </Row>
          {/** 
          <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(totalPage), (item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => handlePageClick(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row>
          */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Inicio);

import React, { useState, useRef, useEffect  } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { authProtectedRoutes, publicRoutes } from "./index";




const Authmiddleware = (props) => {

  const [bool, setBool] = useState(false);
  const revisarBool = useRef(false);

  //otro intento

/** 
  useEffect(() => {
    const fetchData = async () => {
    if (localStorage.getItem("authUser")) {
      console.log("accesando a middleware")
      
      axios
      .get("https://servidorpruebapp.itnmexico.com/auth/autenticar", {
        headers: {
          accessToken: localStorage.getItem("authUser"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.log("usario erróneo")
          revisarBool.current = false;
          setBool(false);
        } else {
          console.log("usuario válido")
          revisarBool.current = true;
          setBool(true);
        }
      });
    }
  
  else{
    revisarBool.current = false;
    setBool(false);
  }
  }
    fetchData();
    }, [bool])

    */

   useEffect(() => {
    
    if (localStorage.getItem("authUser")) {
      console.log("accesando a middleware")
      
      axios
      .get("https://servidorpruebapp.itnmexico.com/auth/autenticar", {
        headers: {
          accessToken: localStorage.getItem("authUser"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.log("usario erróneo")
          //revisarBool.current = false;
          setBool(false);
        } else {
          console.log("usuario válido")
          //revisarBool.current = true;
          setBool(true);
        }
      });
    }
  
  else{
    revisarBool.current = false;
    setBool(false);
  }
  
    
    }, [bool])

    if (!bool) {
      console.log("entrando a usuario inválido")
      return (
        <React.Fragment>
        {publicRoutes[1].component}
      </React.Fragment>
        );
        
          //<Navigate to={{ pathname: "/login", state: { from: props.location } }} />
        //<div>Loading...</div>
      
      
    }
    else{
      console.log("entrando a usuario válido")
      return (<React.Fragment>
        {props.children}
      </React.Fragment>);
    }

  /*
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
*/

/*
const Authmiddleware = (props) => {
  //const history = useHistory();

  if (localStorage.getItem("authUser")) {
    console.log("accesando a middleware")
    axios
      .get("https://servidorpruebapp.itnmexico.com/auth/autenticar", {
        headers: {
          accessToken: localStorage.getItem("authUser"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.log("usario erróneo")
          //useNavigate("/login");
          //const history = useHistory();
          //history.push("/login");
          return (
            <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
          );
        } else {
          console.log("usuariio válido")
          return (
            <React.Fragment>
              {props.children}
            </React.Fragment>
            );
        }
      });
  
    
    
  }
  else{
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};
*/

//otro intento

/*

if(revisarBool){
  console.log("bool de middleware es verdadero")
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
    );
}
else{
  if (localStorage.getItem("authUser")) {
    console.log("accesando a middleware")
    axios
      .get("https://servidorpruebapp.itnmexico.com/auth/autenticar", {
        headers: {
          accessToken: localStorage.getItem("authUser"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          console.log("usario erróneo")
          revisarBool.current = false;
          setBool(false);
        } else {
          console.log("usuariio válido")
          revisarBool.current = true;
          setBool(true);
        }
      });
  }
  else{
    revisarBool.current = false;
    setBool(false);
  }
  return (
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  );
}
*/
  
  
};

export default Authmiddleware;
